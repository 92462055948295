.App {
  margin-top: 15px;
}

.App .navbar {
  background: #29373a;
}

.App .navbar-brand {
  font-weight: bold;
  padding: 0px 15px 0px 10px;
}

.App #arno-dropdown {
  font-weight: bold;
}

.App .navbar-default .navbar-nav>li>a {
  color: #fff;
  background: #29373a;
}
.App .navbar-default .navbar-nav>.active>a {
  background: #435154;
  color: #fff;
}

.App .navbar-default .navbar-nav>li>a:hover {
  background: #5C6A6D;
  color: #fff;
}

.App .logo {
  max-width: 329px;
  max-height: 84px;
}

.App .loading {
  margin: 0 auto;
}