.AccountManagement div.row {
  padding: 12px 0px;
}

.AccountManagement div.padder {
  padding: 60px 0px;
}

.AccountManagement .center {
  margin: 0 auto;
  text-align: center;
}

.AccountManagement .align-right {
  text-align: right;
}

.AccountManagement .align-left {
  text-align: left;
}

.AccountManagement .align-center {
  text-align: center;
}

.AccountManagement .panelheader {
  background-color: #f9f9f9;
}

.AccountManagement div.bold-text {
  font-weight: bold;
  font-size: 14pt;
}

.AccountManagement div.spacer {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
}

.AccountManagement div.buttoner {
  max-width: 320px;
  padding-top: 20px;
  margin: 0 auto;
}