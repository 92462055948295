.Display div.row {
  padding: 12px 0px;
}

.Display div.padless {
  padding: 0px;
}

.Display div.padder {
  padding: 60px 0px;
}

.Display .center {
  margin: 0 auto;
  text-align: center;
}

.Display .align-right {
  text-align: right;
}

.Display .align-left {
  text-align: left;
}

.Display .align-center {
  text-align: center;
}

.Display div.bold-text {
  font-weight: bold;
  font-size: 14pt;
}

.Display div.title {
  font-weight: bold;
  font-size: 26pt;
}

.Display div.spacer {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
}

.Display div.spacer2 {
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.Display .line {
  border-color: #ddd;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Display #activitypanel {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-width: 0px;
}

.Display #balancespanel {
  border-top-left-radius: 0px;
}

.Display #coname {
  padding: none;
}

.Display .panelheader {
  background-color: #f9f9f9;
}

.Display #coaddr {
  padding: none;
}

.Display #toppanel {
  padding-bottom: 0px;
}

.Display .openitemstable td {
  cursor: pointer;
}