  .Invoice div.row {
    padding: 12px 0px;
  }

  .Invoice div.padless {
    padding: 0px;
  }

  .Invoice div.padder {
    padding: 60px 0px;
  }

  .Invoice div.rightside {
    padding-left: 20px;
    border-left-width: 1px;
    border-left-color: #000;
    border-left-style: solid;
  }

  .Invoice .center {
    margin: 0 auto;
    text-align: center;
  }

  .Invoice .align-right {
    text-align: right;
  }

  .Invoice .align-left {
    text-align: left;
  }

  .Invoice .align-center {
    text-align: center;
  }

  .Invoice div.bold-text {
    font-weight: bold;
    font-size: 14pt;
  }

  .Invoice div.title {
    font-weight: bold;
    font-size: 26pt;
  }

  .Invoice div.spacer {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
  }
  
  .Invoice div.spacer2 {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
  }
  
  .Invoice div.spacerleft {
    margin-left: 10px;
  }
  
  .Invoice div.spacerright {
    margin-right: 10px;
  }

  .Invoice div.marginless {
    margin: 0px;
  }

  .Invoice .tablename {
    font-weight: bold;
    font-size: 22pt;
  }

  .Invoice .line {
    border-color: #ddd;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .Invoice .printbutton {
    display:inline-block;
  }

  .Invoice .floatleft {
    float: left
  }

  .Invoice .floatright {
    float: right
  }

  .Invoice .infopanel {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-width: 0px !important;
  }

  .Invoice .paymentspanel {
    border-top-left-radius: 0px !important;
  }

  .Invoice #detailshead {
    padding: 0px;
    margin: 0px;
  }

  .Invoice #coname {
    padding: none;
  }

  .Invoice #mainheader {
    background-color: #f9f9f9;
  }

  .Invoice #coaddr {
    padding: none;
  }