.User {
  padding: 60px 0;
}

.User form {
  margin: 0 auto;
  max-width: 320px;
}

.User .center {
  margin: 0 auto;
  text-align: center;
}

.User .align-right {
  text-align: right;
}

.User .align-left {
  text-align: left;
}

.User .align-center {
  text-align: center;
}

.User div.bold-text {
  font-style: italic;
  font-size: 14px;
}

.User div.top-padding {
  padding-top: 10px;
}

.User .buttonlabel {
  /* margin-left: 13.955px;
  margin-right: 27.955px; */
  margin-right: 15px;
  margin-left: 55px;
}

.User .left-margin {
  margin-left: 15px;
}