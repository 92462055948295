.Register {
  padding: 60px 0;
}

.Register form {
  margin: 0 auto;
  max-width: 320px;
}

.Register .center {
  margin: 0 auto;
  text-align: center;
}

.Register .align-right {
  text-align: right;
}

.Register .align-left {
  text-align: left;
}

.Register .align-center {
  text-align: center;
}
