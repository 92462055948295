.Accounts div.row {
  padding: 12px 0px;
}

.Accounts div.padder {
  padding: 60px 0px;
}

.Accounts .center {
  margin: 0 auto;
  text-align: center;
}

.Accounts .align-right {
  text-align: right;
}

.Accounts .align-left {
  text-align: left;
}

.Accounts .align-center {
  text-align: center;
}

.Accounts .panelheader {
  background-color: #f9f9f9;
}

.Accounts div.bold-text {
  font-weight: bold;
  font-size: 14pt;
}

.Accounts div.spacer {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
}