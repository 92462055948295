.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.Login .center {
  margin: 0 auto;
  text-align: center;
}

.Login .align-right {
  text-align: right;
}

.Login .align-left {
  text-align: left;
}

.Login .align-center {
  text-align: center;
}

.Login #error-message {
  text-align: center;
  color: red;
  font-size: 11pt;
  font-weight: bold;
  font-style: italic;
  padding-top: 14px;
}
