.App {
  margin-top: 15px;
}

.App .navbar {
  background: #29373a;
}

.App .navbar-brand {
  font-weight: bold;
  padding: 0px 15px 0px 10px;
}

.App #arno-dropdown {
  font-weight: bold;
}

.App .navbar-default .navbar-nav>li>a {
  color: #fff;
  background: #29373a;
}
.App .navbar-default .navbar-nav>.active>a {
  background: #435154;
  color: #fff;
}

.App .navbar-default .navbar-nav>li>a:hover {
  background: #5C6A6D;
  color: #fff;
}

.App .logo {
  max-width: 329px;
  max-height: 84px;
}

.App .loading {
  margin: 0 auto;
}
.Display div.row {
  padding: 12px 0px;
}

.Display div.padless {
  padding: 0px;
}

.Display div.padder {
  padding: 60px 0px;
}

.Display .center {
  margin: 0 auto;
  text-align: center;
}

.Display .align-right {
  text-align: right;
}

.Display .align-left {
  text-align: left;
}

.Display .align-center {
  text-align: center;
}

.Display div.bold-text {
  font-weight: bold;
  font-size: 14pt;
}

.Display div.title {
  font-weight: bold;
  font-size: 26pt;
}

.Display div.spacer {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
}

.Display div.spacer2 {
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.Display .line {
  border-color: #ddd;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Display #activitypanel {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-width: 0px;
}

.Display #balancespanel {
  border-top-left-radius: 0px;
}

.Display #coname {
  padding: none;
}

.Display .panelheader {
  background-color: #f9f9f9;
}

.Display #coaddr {
  padding: none;
}

.Display #toppanel {
  padding-bottom: 0px;
}

.Display .openitemstable td {
  cursor: pointer;
}
.Request {
    padding: 20px 0;
  }
  
  .Request form {
    margin: 0 auto;
  }
  
  .Request .center {
    margin: 0 auto;
    text-align: center;
  }
  
  .Request .align-right {
    text-align: right;
  }
  
  .Request .align-left {
    text-align: left;
  }
  
  .Request .align-center {
    text-align: center;
  }

  .Request .right-leaning {
    padding-left: 70px;
    padding-right: 35px;
  }

  .Request .left-leaning {
    padding-left: 35px;
    padding-right: 70px;
  }

  .Request .side-padded {
    padding-left: 70px;
    padding-right: 70px;
  }
  
  .Request .bold-text {
    font-weight: bold;
  }

  .Request .titled {
    font-weight: bold;
    font-size: 14pt;
  }

  .Request .tightened {
    padding-left: 40%;
    padding-right: 40%;
    padding-top: 26px;
  }

  .Request .vert-spaced {
    padding-top: 14px;
    padding-bottom: 22px;
  }

  .Request .padless {
    padding: 0px;
  }

  .Request .marginless {
    margin: 0px;
  }

  .Request .asterisk {
    color: red;
  }
  .Invoice div.row {
    padding: 12px 0px;
  }

  .Invoice div.padless {
    padding: 0px;
  }

  .Invoice div.padder {
    padding: 60px 0px;
  }

  .Invoice div.rightside {
    padding-left: 20px;
    border-left-width: 1px;
    border-left-color: #000;
    border-left-style: solid;
  }

  .Invoice .center {
    margin: 0 auto;
    text-align: center;
  }

  .Invoice .align-right {
    text-align: right;
  }

  .Invoice .align-left {
    text-align: left;
  }

  .Invoice .align-center {
    text-align: center;
  }

  .Invoice div.bold-text {
    font-weight: bold;
    font-size: 14pt;
  }

  .Invoice div.title {
    font-weight: bold;
    font-size: 26pt;
  }

  .Invoice div.spacer {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
  }
  
  .Invoice div.spacer2 {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
  }
  
  .Invoice div.spacerleft {
    margin-left: 10px;
  }
  
  .Invoice div.spacerright {
    margin-right: 10px;
  }

  .Invoice div.marginless {
    margin: 0px;
  }

  .Invoice .tablename {
    font-weight: bold;
    font-size: 22pt;
  }

  .Invoice .line {
    border-color: #ddd;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .Invoice .printbutton {
    display:inline-block;
  }

  .Invoice .floatleft {
    float: left
  }

  .Invoice .floatright {
    float: right
  }

  .Invoice .infopanel {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-width: 0px !important;
  }

  .Invoice .paymentspanel {
    border-top-left-radius: 0px !important;
  }

  .Invoice #detailshead {
    padding: 0px;
    margin: 0px;
  }

  .Invoice #coname {
    padding: none;
  }

  .Invoice #mainheader {
    background-color: #f9f9f9;
  }

  .Invoice #coaddr {
    padding: none;
  }
.Accounts div.row {
  padding: 12px 0px;
}

.Accounts div.padder {
  padding: 60px 0px;
}

.Accounts .center {
  margin: 0 auto;
  text-align: center;
}

.Accounts .align-right {
  text-align: right;
}

.Accounts .align-left {
  text-align: left;
}

.Accounts .align-center {
  text-align: center;
}

.Accounts .panelheader {
  background-color: #f9f9f9;
}

.Accounts div.bold-text {
  font-weight: bold;
  font-size: 14pt;
}

.Accounts div.spacer {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
}
.SimpleCount {
  padding: 60px 0;
}

.SimpleCount form {
  margin: 0 auto;
  max-width: 320px;
}

.SimpleCount .center {
  margin: 0 auto;
  text-align: center;
}

.SimpleCount .align-right {
  text-align: right;
}

.SimpleCount .align-left {
  text-align: left;
}

.SimpleCount .align-center {
  text-align: center;
}

.SimpleCount div.bold-text {
  font-style: bold;
  font-size: 14px;
}

.SimpleCount div.top-padding {
  padding-top: 10px;
}

.SimpleCount .buttonlabel {
  /* margin-left: 13.955px;
  margin-right: 27.955px; */
  margin-right: 15px;
  margin-left: 55px;
}

.SimpleCount .left-margin {
  margin-left: 15px;
}
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
  }
  
  .react-tagsinput--focused {
    border-color: #a5d24a;
  }
  
  .react-tagsinput-tag {
    background-color: #cde69c;
    border-radius: 2px;
    border: 1px solid #a5d24a;
    color: #638421;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }
  
  .react-tagsinput-tag a::before {
    content: " \D7";
  }
  
  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
  }
.AccountManagement div.row {
  padding: 12px 0px;
}

.AccountManagement div.padder {
  padding: 60px 0px;
}

.AccountManagement .center {
  margin: 0 auto;
  text-align: center;
}

.AccountManagement .align-right {
  text-align: right;
}

.AccountManagement .align-left {
  text-align: left;
}

.AccountManagement .align-center {
  text-align: center;
}

.AccountManagement .panelheader {
  background-color: #f9f9f9;
}

.AccountManagement div.bold-text {
  font-weight: bold;
  font-size: 14pt;
}

.AccountManagement div.spacer {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
}

.AccountManagement div.buttoner {
  max-width: 320px;
  padding-top: 20px;
  margin: 0 auto;
}
.Users div.row {
  padding: 12px 0px;
}

.Users div.padder {
  padding: 60px 0px;
}

.Users .center {
  margin: 0 auto;
  text-align: center;
}

.Users .align-right {
  text-align: right;
}

.Users .align-left {
  text-align: left;
}

.Users .align-center {
  text-align: center;
}

.Users .panelheader {
  background-color: #f9f9f9;
}

.Users div.bold-text {
  font-weight: bold;
  font-size: 14pt;
}

.Users div.spacer {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
}
.User {
  padding: 60px 0;
}

.User form {
  margin: 0 auto;
  max-width: 320px;
}

.User .center {
  margin: 0 auto;
  text-align: center;
}

.User .align-right {
  text-align: right;
}

.User .align-left {
  text-align: left;
}

.User .align-center {
  text-align: center;
}

.User div.bold-text {
  font-style: italic;
  font-size: 14px;
}

.User div.top-padding {
  padding-top: 10px;
}

.User .buttonlabel {
  /* margin-left: 13.955px;
  margin-right: 27.955px; */
  margin-right: 15px;
  margin-left: 55px;
}

.User .left-margin {
  margin-left: 15px;
}
.Login {
  padding: 60px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.Login .center {
  margin: 0 auto;
  text-align: center;
}

.Login .align-right {
  text-align: right;
}

.Login .align-left {
  text-align: left;
}

.Login .align-center {
  text-align: center;
}

.Login #error-message {
  text-align: center;
  color: red;
  font-size: 11pt;
  font-weight: bold;
  font-style: italic;
  padding-top: 14px;
}

.Register {
  padding: 60px 0;
}

.Register form {
  margin: 0 auto;
  max-width: 320px;
}

.Register .center {
  margin: 0 auto;
  text-align: center;
}

.Register .align-right {
  text-align: right;
}

.Register .align-left {
  text-align: left;
}

.Register .align-center {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

